<template>
  <section>
    <backtoolbar
      hasDrawer="true"
      title="Reservations"
      bgcolor="white"
      routename="menu"
      @open-drawer="$emit('open-drawer')"
    ></backtoolbar>
    <v-main>
      <CentralLoader v-if="isLoading"></CentralLoader>
      <v-container v-else>
        <template>
          <v-tabs v-model="tabs" centered class="border-bottom">
            <v-tab class="text-capitalize">History</v-tab>
            <v-tab class="text-capitalize">Upcoming</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <!-- searchfield -->
              <ReservationsListWithSearch :list="history" />
            </v-tab-item>
            <v-tab-item>
              <!-- searchfield -->
              <ReservationsListWithSearch :list="upcoming" />
            </v-tab-item>
          </v-tabs-items>
        </template> </v-container
    ></v-main>
  </section>
</template>

<script>
import backtoolbar from '../../components/partials/BackToolbar';
import CentralLoader from '@/components/Loaders/CentralLoader';
import ReservationsListWithSearch from '@/components/Reservation/ReservationsListWithSearch';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    CentralLoader,
    backtoolbar,
    ReservationsListWithSearch,
  },
  data: function () {
    return {
      isLoading: false,
      tabs: null,
      resdata: null,
    };
  },
  methods: {
    ...mapActions('reservation', ['listReservation']),
  },
  computed: {
    ...mapState('reservation', {
      history: (state) => state.history,
      upcoming: (state) => state.upcoming,
    }),
  },
  created() {
    this.isLoading = true;
    this.listReservation()
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style></style>
